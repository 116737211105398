

























































































































































































































































































































































































































import { computed, defineComponent, onMounted, PropType, ref, watch } from '@nuxtjs/composition-api';
import { keyBy } from 'lodash-es';
import { useFlashSale } from '~/features/flashsale';
import { useHomePageFeaturedCategories } from '~/features/categories';
import { useSeoMeta } from '~/features/seo';
import { useFeatureFlags, useStoreConfig } from '~/features/storeConfig';
import { useHomePage } from '~/features/home';
import { useI18n } from '~/features/i18n';
import { useAuth } from '~/features/auth';
import { useRouter } from '~/features/router';
import { useAlerts } from '~/features/alerts';
import useCookies from '~/features/cookies';
import { useCustomerLimitInfo } from '~/features/customer';
import { getTriviaContests } from '~/features/triviaGame';

export default defineComponent({
  name: 'Home',
  props: {
    type: {
      type: String as PropType<'default' | 'elite'>,
      default: 'default',
    },
    eliteBanners: {
      type: Boolean,
      default: true,
    },
  },
  setup({ type }) {
    const { t } = useI18n();
    const { featuredCategories } = useHomePageFeaturedCategories();
    const { flashSale } = useFlashSale();
    const { offerLabel, homeFooterContent, promotedCategory, ramadanThemeEnabled } = useStoreConfig();
    const { getLimitInfo } = useCustomerLimitInfo();
    const { fetchFlags } = useFeatureFlags();
    const { route } = useRouter();
    const { isLoggedIn } = useAuth();
    const { setCookie, removeCookie } = useCookies();
    const { success, error } = useAlerts();
    const {
      topDeals,
      homeOffers,
      productBrandSlider,
      productUpperCategorySlider,
      productLowerCategorySlider,
      mappedHomeOffers,
      metaData,
      featuredBrands,
      bestSellers,
    } = useHomePage(type);
    const { triviaContests, refetch } = getTriviaContests();

    watch(isLoggedIn, () => {
      refetch();
    });

    const showTriviaGame = computed(
      () =>
        triviaContests?.value &&
        triviaContests.value.questions.length &&
        !triviaContests.value.user_completed_contest &&
        featureFlags.value?.triviaGame?.enabled
    );

    /**
     * if customer is paying with e-wallets for installments he'll be redirected to homepage
     * with a query param success=true if the payment is successful
     */
    const walletPaymentSuccess = computed(() => route.value.query.success);
    const featureFlags = ref<any>(undefined);

    onMounted(async () => {
      if (walletPaymentSuccess.value) {
        success(t('walletPaymentSuccess').toString());
        // if the success param is available but with value false -> then payment error
      } else if (walletPaymentSuccess.value === 'false') {
        error(t('walletPaymentError').toString());
      }
      fetchFlags().then(data => {
        featureFlags.value = keyBy(data.data?.features, 'feature_name');
      });
      const { data: limitData } = await getLimitInfo();
      // Check if the customer has no installments account => set cookie to make the user eligible for taksety page
      if (!limitData?.getCustomerCredit.credit_limits?.length) {
        // set cookie to use it in taksety middleware
        setCookie('pending_approval', 'true');
      } else {
        // remove the cookie if it exists and the customer has an account
        removeCookie('pending_approval');
      }
    });

    useSeoMeta({
      title: metaData.value?.meta_title
        ? `${metaData.value?.meta_title}`
        : t('seo.title').toString() + ' | ' + t('seo.description').toString(),
      description: metaData.value?.meta_description
        ? metaData.value.meta_description
        : (t('seo.description') as string),
    });

    return {
      topDeals,
      featuredCategories,
      brands: featuredBrands,
      bestSellers,
      offerLabel,
      offers: homeOffers,
      mappedHomeOffers,
      productBrandSlider,
      productUpperCategorySlider,
      productLowerCategorySlider,
      homeFooterContent,
      promotedCategory,
      isLoggedIn,
      walletPaymentSuccess,
      flashSale,
      featureFlags,
      ramadanThemeEnabled,
      triviaContests,
      showTriviaGame,
    };
  },
  head: {},
});
