import { render, staticRenderFns } from "./Default.vue?vue&type=template&id=230cf9b2&scoped=true&"
import script from "./Default.vue?vue&type=script&lang=ts&"
export * from "./Default.vue?vue&type=script&lang=ts&"
import style0 from "./Default.vue?vue&type=style&index=0&id=230cf9b2&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "230cf9b2",
  null
  
)

/* custom blocks */
import block0 from "./Default.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FlashSaleBanner: require('/builds/robustastudio/raya/b2c/raya-b2c-frontend/components/FlashSaleBanner.vue').default,OfferCard: require('/builds/robustastudio/raya/b2c/raya-b2c-frontend/components/OfferCard.vue').default,OfferSlider: require('/builds/robustastudio/raya/b2c/raya-b2c-frontend/components/OfferSlider.vue').default,FeaturedCategory: require('/builds/robustastudio/raya/b2c/raya-b2c-frontend/components/FeaturedCategory.vue').default,HomeEliteRayaPlusPointsBanner: require('/builds/robustastudio/raya/b2c/raya-b2c-frontend/components/Home/Elite/RayaPlusPointsBanner.vue').default,HomeEliteRayaPlusPointsBasicBanner: require('/builds/robustastudio/raya/b2c/raya-b2c-frontend/components/Home/Elite/RayaPlusPointsBasicBanner.vue').default,MainTitle: require('/builds/robustastudio/raya/b2c/raya-b2c-frontend/components/MainTitle.vue').default,ProductCard: require('/builds/robustastudio/raya/b2c/raya-b2c-frontend/components/ProductCard.vue').default,LoadingProductSlider: require('/builds/robustastudio/raya/b2c/raya-b2c-frontend/components/Loading/ProductSlider.vue').default,LazyLoader: require('/builds/robustastudio/raya/b2c/raya-b2c-frontend/components/LazyLoader.vue').default,AppButtonV2: require('/builds/robustastudio/raya/b2c/raya-b2c-frontend/components/AppButtonV2.vue').default,AppLink: require('/builds/robustastudio/raya/b2c/raya-b2c-frontend/components/AppLink.js').default,HomeEliteRayaEliteBrandsBanner: require('/builds/robustastudio/raya/b2c/raya-b2c-frontend/components/Home/Elite/RayaEliteBrandsBanner.vue').default,PredictAndWinHomeButton: require('/builds/robustastudio/raya/b2c/raya-b2c-frontend/components/PredictAndWin/HomeButton.vue').default})
